import React from "react"
import Button from "../button"
import { Input } from "../form-elements"
import styles from "./application-form.module.scss"

export default function ApplicationForm() {
  const [submitText, setSubmitText] = React.useState("Gönder")

  return (
    <form
      className={styles.form}
      name="Egitim Basvurusu Formu"
      netlify-honeypot="bot-field"
      method="POST"
      action="/tesekkurler"
      data-netlify="true"
      onSubmit={e => setSubmitText("Gönderiliyor...")}
    >
      <input type="hidden" name="bot-field" />
      <input type="hidden" name="form-name" value="Egitim Basvurusu Formu" />
      <div>
        <Input
          type="text"
          name="adsoyad"
          placeholder="Adınız Soyadınız"
          required
        />
        <Input
          type="email"
          name="eposta"
          placeholder="E-Posta Adresiniz"
          required
        />
        <Input
          type="text"
          name="telefon"
          placeholder="Telefon Numaranız"
          required
        />
        <Input
          type="text"
          name="basvurmakIstediginizEgitim"
          placeholder="Başvurmak İstediğiniz Eğitim"
        />
        <Input
          type="text"
          name="mezunOldugunuzFakulte"
          placeholder="Mezun Olduğunuz Fakülte"
        />
        <Input
          type="text"
          name="mezunOldugunuzBolum"
          placeholder="Mezun Olduğunuz Bölüm"
        />
      </div>
      <div>
        <Button type="submit">{submitText}</Button>
      </div>
    </form>
  )
}
