import React from "react"
import cn from "classnames"
import styles from "./input.module.scss"

export function Input({ className, ...props }) {
  return <input className={cn(styles.input, className)} {...props} />
}

export function Textarea({ className, ...props }) {
  return (
    <textarea className={cn(styles.input, className)} {...props}></textarea>
  )
}
